const dayjs = require("dayjs");

export const formatDateInPDT = (date, isUTC) => {
  const dateObj = isUTC ? dayjs.utc(date) : dayjs(date);

  const formattedDateTime = dateObj
    .tz("America/Los_Angeles")
    .format("DD-MMM-YYYY hh:mm A");

  return formattedDateTime;
};
