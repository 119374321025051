import React from "react";
import HomeForm from "./HomeForm";

export default function HomeContactUs() {
  return (
    <div
      id="tools"
      className="h-auto bg-white flex flex-col md:flex-row items-center"
    >
      <div className="w-full md:w-1/2  md:py-20 py-0 md:pl-32 text-xl md:text-2xl ">
        <p className="leading-10">
          It's Hard To Fit Everything
          <br />
          We Do Onto One Page
        </p>
        <p className="leading-10">
          {"But if you're still interested in"}
          <br />
          {"learning more about how Smorgborg"}
          <br />
          {"can help your organization, just"}
          <br />
          {"fill out our contact form and"}
          <br />
          {"we can set up a personal product"}
          <br />
          {"demo for you with our founder, John."}
        </p>
      </div>
      <div className="w-full md:w-1/2 p-8 md:py-20 text-2xl">
        <HomeForm />
      </div>
    </div>
  );
}
