import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StatisticsWidget = ({ label, icon, count }) => {
  return (
    <div className="flex items-center justify-center space-x-2 bg-white p-4 rounded-md shadow-md">
      <div className="text-4xl text-blue-500">
        <FontAwesomeIcon icon={icon} />
      </div>
      <div>
        <p className="text-gray-500">{label}</p>
        <p className="text-2xl font-bold">{count}</p>
      </div>
    </div>
  );
};

export default StatisticsWidget;
