import React from 'react'

function DefaultFoolter({ orgData, user }) {
    const HtmlToReactParser = require('html-to-react').Parser;
    const htmlToReactParser = new HtmlToReactParser();
    let des = htmlToReactParser.parse(orgData?.email_Footer);
    return (
        <div bgcolor="#E2E2E2" style={{textAlign: "-webkit-center", margin: "0 auto", width:"100%"}}>
            <table
            border="0"
            cellSpacing="0"
            cellPadding="0"
            width="600"
            align="center"
            bgcolor="#E2E2E2"
        >
            <tbody>
            <tr>
                <td
                align="center"
                bgcolor="#E2E2E2"
                style={{ padding: '16px', background: "#E2E2E2", borderRadius:'2px', textAlign: "center" }}
                >
                    <h3> {new Date().getFullYear()} {orgData?.OrganizationName} - All Rights Reserved</h3>
                    <p style={{ fontSize: '16px', fontWeight: "300" }}>
                        {des}
                    </p>
                    <p style={{ fontSize: '16px', fontWeight: "300" }}>{orgData?.address?.Street} <br />
                        {orgData?.address?.City} {orgData?.address?.State}  {orgData?.address?.Pincode}
                    </p>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    )
}

export default DefaultFoolter
