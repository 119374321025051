import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { API_URL_CONSTANT } from "../../constants/apiConstant";
import { getReqParamheader } from "../../services/apiCall";
import { getItem } from "../../utils/storage";
import { Breadcrumb } from "antd";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
import MetaDes from "../../components/MetaDes";
import BaseBackButton from "../../components/BaseBackButton";
import { ToastContainer, toast } from "react-toastify";
import PageSnipper from "../../components/PageSnipper";

function View() {
  const [articleId, setArticleId] = useState("");
  const [data, setData] = useState({});
  const [orgName, setOrgName] = useState("");
  const path = useLocation().pathname;
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const HtmlToReactParser = require("html-to-react").Parser;
  const htmlToReactParser = new HtmlToReactParser();
  const [description, setDescription] = useState("");
  let des = htmlToReactParser.parse(data?.draft?.Content);
  // let currentorg = getItem('currentOrg')
  let currentOrgid = getItem("org_id");
  let token = getItem("token");
  const navigate = useNavigate();
  useEffect(() => {
    let article = path.split("/")[2];
    setArticleId(article);
    let org = searchParams.get("org");
    setOrgName(org);
  }, []);
  useEffect(() => {
    if (articleId) {
      fetchArticledata(articleId);
    }
  }, [articleId]);

  const fetchArticledata = (id) => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/article_details/${id}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("get article by id response", res?.data);
          let dataa = res?.data?.data;

          let desp = dataa?.draft?.Content.replace(/<[^>]*>?/gm, "").substring(
            0,
            100
          );
          setDescription(desp);
          setLoading(false);
          // console.log(dataa);

          setData(dataa);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        setTimeout(() => {
          toast.error(`${err?.response?.data?.massage} .....`);
        }, 1000);
      });
  };
  // const handleOrg = () => {
  //     navigate(`/manage/${currentOrgid}`)
  // }
  var newDateOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  const handleOrg = () => {
    navigate(`/manage/${currentOrgid}`);
  };

  const navigateToArticleList = () => {
    navigate(`/manage/${currentOrgid}/article`);
  };

  return (
    <div className="flex justify-center min-h-screen bg-gray-100">
      {loading ? (
        <PageSnipper loading={loading} />
      ) : (
        <div
          className="w-[50%] px-8 py-4 mt-4 bg-white"
          style={{
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
            marginBottom: "3%",
          }}
        >
          <Breadcrumb
            items={[
              {
                title: (
                  <button onClick={navigateToArticleList}>Articles</button>
                ),
              },
              {
                title: data?.draft?.Title,
              },
            ]}
          />

          <div className="w-full mt-0 flex justify-center">
            {data?.draft?.Featured_Photo && (
              <img
                src={data?.draft?.Featured_Photo}
                className="max-h-[600px] max-w-[600px] px-4 py-4"
              />
            )}
            <MetaDes
              image={data?.draft?.Featured_Photo}
              title={data?.draft?.Title}
              description={description}
            />
          </div>
          <p className="">{data?.draft?.Title} </p>
          <div className="flex justify-between items-start  mt-2">
            <div className="text-gray-600 text-1xl">
              <p
                className="text-gray-600 cursor-pointer hover:text-blue-800"
                onClick={handleOrg}
              >
                {" "}
                {orgName}{" "}
              </p>
              {new Date(data?.publishedAt).toLocaleString(
                "en-US",
                newDateOptions
              )}
            </div>

            <div className="text-gray-600 text-1xl flex items-center gap-3">
              Share
              <FacebookShareButton
                url={`https://smorgborg.org/${path}`}
                // quote={'heyyy'}
                // hashtag="#camperstribe"
              >
                <FacebookIcon size={32} />
              </FacebookShareButton>
              <TwitterShareButton
                url={`https://smorgborg.org/${path}`}
                // title={"CampersTribe - World is yours to explore"}
                // hashtag="#camperstribe"
              >
                <TwitterIcon size={32} />
              </TwitterShareButton>
              {/* <img src="https://smorgborg.imgix.net/assets/asset_l.png?fit=max&h=32&h=32" alt='linkedin icon' /> */}
            </div>
          </div>
          <hr style={{ marginTop: "4px" }} />

          <div className="w-full mt-2 mb-4">{des}</div>
          <BaseBackButton
            type="button"
            text="Go Back to Article List"
            clickHandler={navigateToArticleList}
          />
        </div>
      )}
    </div>
  );
}

export default View;
