import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Modal from "../../components/Modal";
import { API_URL_CONSTANT } from "../../constants/apiConstant";
import {
  postReqParamheaderFile,
  getReqParamheader,
  deleteReqParamheader,
  postReqParamheader,
} from "../../services/apiCall";
import { getItem } from "../../utils/storage";
import PageSnipper from "../../components/PageSnipper";

const EmptyAlbum = ({ type, title, photo, i, remove, path }) => {
  return (
    <div className="h-[200px] w-[200px] border rounded-sm">
      <div
        className="h-[100px] flex justify-center items-center cursor-pointer"
        style={{ background: "#808080" }}
      >
        {photo > 0 && path ? (
          <img
            style={{ objectFit: "contain" }}
            src={path}
            alt="cover"
            className="h-[100px] w-[100px]"
          />
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="#fff"
            className="w-10 h-10"
          >
            <path d="M12 9a3.75 3.75 0 100 7.5A3.75 3.75 0 0012 9z" />
            <path
              fillRule="evenodd"
              d="M9.344 3.071a49.52 49.52 0 015.312 0c.967.052 1.83.585 2.332 1.39l.821 1.317c.24.383.645.643 1.11.71.386.054.77.113 1.152.177 1.432.239 2.429 1.493 2.429 2.909V18a3 3 0 01-3 3h-15a3 3 0 01-3-3V9.574c0-1.416.997-2.67 2.429-2.909.382-.064.766-.123 1.151-.178a1.56 1.56 0 001.11-.71l.822-1.315a2.942 2.942 0 012.332-1.39zM6.75 12.75a5.25 5.25 0 1110.5 0 5.25 5.25 0 01-10.5 0zm12-1.5a.75.75 0 100-1.5.75.75 0 000 1.5z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </div>
      <div className="h-[100px] bg-white border-b">
        <div className="px-8 py-3 border-b">
          <p> {title}</p>
          <p className="text-gray-600">{photo} Photos</p>
        </div>
        {type === "album" && photo === 0 && (
          <div className="flex justify-center py-1">
            <span onClick={() => remove(i)} className="cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

function PhotoHome() {
  const [albumList, setAlbumList] = useState([]);
  const [activeAlbum, setActiveAlbum] = useState({
    name: "",
    data: [],
  });
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [data, setData] = useState({
    name: "",
    des: "",
  });
  const [imgfile, uploadimg] = useState({});
  const [preView, setPreview] = useState({});
  const [preViewShow, setPreviewShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [org, setOrg] = useState("");
  const [user, setUser] = useState("");
  const path = useLocation().pathname;
  // const forceUpdate = useCallback(() => setActiveAlbum({...activeAlbum}), []);

  useEffect(() => {
    let userid = getItem("user_id");
    let orgid = path.split("/")[2];
    setOrg(orgid);
    setUser(userid);
    setActiveAlbum({ ...activeAlbum, name: "Unsorted" });
  }, []);

  useEffect(() => {
    if (org && user) {
      fetchPhotos();
      albumDetails();
    }
  }, [org]);

  // useEffect(() => {
  //     setActiveAlbum({ ...activeAlbum, name: 'Unsorted' })
  // }, [])

  const albumDetails = () => {
    let url = `${API_URL_CONSTANT.baseUrl}/albumdetails/${org}/${user}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          // console.log('get all album response', res?.data)
          setAlbumList(res?.data?.data);
          // forceUpdate()
          // let test = activeAlbum
          // if(test?.data?.Album_Name !== 'unsorted'){
          //   setActiveAlbum({ ...activeAlbum, name: test?.Album_Name, data: test?.data, Photos: test?.data?.photoslist })
          // }
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  const handleCreateAlbum = () => {
    setShowModal(false);
    let al = {};
    al.Album_Name = data?.name;
    al.Album_Description = data?.des;
    if (al?.Album_Name) {
      createAlbum(al);
    } else {
      alert("fill all required");
    }
  };
  const createAlbum = (data) => {
    let url = `${API_URL_CONSTANT.baseUrl}/createPhotoalbum/${org}/${user}`;
    postReqParamheader(url, data)
      .then((res) => {
        if (res?.status) {
          // console.log('create album response', res?.data)
          albumDetails();
          setData({
            name: "",
            des: "",
          });
          toast.success(res?.data?.message);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  const handleRemove = (id) => {
    let a = window.confirm("Are you sure you want to delete this album?");
    if (a) {
      if (id) {
        deletePhotoalbum(id);
      }
    }
  };
  const handleActive = (item) => {
    setActiveAlbum({
      ...activeAlbum,
      name: item?.Album_Name,
      data: item,
      Photos: item?.photoslist,
    });
  };
  const imgFilehandler = (e) => {
    if (e.target.files.length !== 0) {
      uploadimg({
        picturePreview: URL.createObjectURL(e.target.files[0]),
        pictureAsFile: e.target.files[0],
      });
    }
  };
  const handleUpload = () => {
    setShow(!show);
  };
  const handleUploadSave = () => {
    setShow(false);
    if (user && org && imgfile) {
      let data = {};
      data.Photo_Title = title;
      data.files = imgfile?.pictureAsFile;
      uploadPhoto(data);
    }
  };
  const handleUploadPhotodirect = () => {
    setShow(false);
    let album_id = activeAlbum?.data?._id;
    if (user && org && imgfile && album_id) {
      let data = {};
      data.Photo_Title = title;
      data.files = imgfile?.pictureAsFile;
      uploadPhotodirect(data, album_id);
    }
  };
  const uploadPhotodirect = (data, id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/uploadPhotodirect/${id}/${org}/${user}`;
    postReqParamheaderFile(url, data)
      .then((res) => {
        if (res?.status) {
          console.log("upload photo in album response", res?.data);
          uploadimg({});
          setTitle("");
          fetchPhotos();
          albumDetails();
          toast.success(res?.data?.message);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };

  const handlePreview = (data) => {
    if (data._id != "999") {
      setPreview(data);
      setPreviewShow(!preViewShow);
    } else {
      alert("Can't preview/move logo");
    }
  };
  const handleRemovePhoto = (id) => {
    let a = window.confirm("Are you sure you want to delete this photo?");
    if (a) {
      setPreviewShow(false);
      if (id && activeAlbum?.name === "Unsorted") {
        removePhoto(id);
      } else {
        removePhotofromAlbum(id);
      }
    }
  };
  const fetchPhotos = () => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/unsortedPhotos/${org}/${user}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("get all photos response", res?.data);
          let photosdata = res?.data?.data;
          let orglogopath = getItem("currentOrgLogo");
          let orglogo = {
            _id: "999",
            tags: [],
            Photo_Path: orglogopath,
            PhotoAlbumId: "",
            orgId: org,
            deleted: false,
            deletedAt: null,
            deletedById: null,
            lastAccessedAt: {
              userId: "",
              time: "",
            },
            modifiedById: "",
            modifiedAt: "",
            createdAt: "",
            updatedAt: "",
            __v: 0,
          };
          photosdata.push(orglogo);
          setActiveAlbum({
            ...activeAlbum,
            Photos: photosdata,
            name: "Unsorted",
          });
          setLoading(false);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };
  const uploadPhoto = (data) => {
    let url = `${API_URL_CONSTANT.baseUrl}/uploadPhotos/${org}/${user}`;
    postReqParamheaderFile(url, data)
      .then((res) => {
        if (res?.status) {
          console.log("upload photo response", res?.data);
          uploadimg({});
          setTitle("");
          fetchPhotos();
          toast.success(res?.data?.message);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  const removePhoto = (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/deletePhotoById/${id}/${org}/${user}`;
    deleteReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("delete photo response", res?.data);
          fetchPhotos();
          toast.success(res?.data?.message);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  const removePhotofromAlbum = (pid) => {
    let url = `${API_URL_CONSTANT.baseUrl}/removephotofromAlbum/${activeAlbum?.data?._id}/${pid}/${org}/${user}`;
    deleteReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("delete album  photo response", res?.data);
          fetchPhotos();
          albumDetails();
          toast.success(res?.data?.message);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  const deletePhotoalbum = (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/deletePhotoalbum/${id}/${org}/${user}`;
    deleteReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("delete album response", res?.data);
          albumDetails();
          toast.success(res?.data?.message);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  const handleAddphotoToalbum = (photo_id, album_id) => {
    if (photo_id && album_id) {
      addphotoToalbum(album_id, photo_id);
    } else {
      alert("missing album id or photo id");
    }
  };
  const addphotoToalbum = (aid, pid) => {
    //
    let url = `${API_URL_CONSTANT.baseUrl}/addphotoToalbum/${aid}/${pid}/${org}/${user}`;
    postReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("add photo to album response", res?.data);
          albumDetails();
          fetchPhotos();
          setPreviewShow(false);
          toast.success(res?.data?.message);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  return (
    <>
      {loading ? (
        <PageSnipper loading={loading} />
      ) : (
        <div className="px-6">
          <p className="text-3xl"> Photo </p>
          <div className="my-4">
            <p className="text-2xl"> Photo Album</p>
            <span
              className="text-small text-blue-500 mt-2 cursor-pointer mb-4"
              onClick={() => setShowModal(!showModal)}
            >
              Create a New Photo Album
            </span>
          </div>
          <div className="w-full border-b pb-8">
            {albumList.length > 0 ? (
              <div className="grid grid-cols-5 gap-4">
                {albumList.map((item, i) => (
                  <div key={i} onClick={() => handleActive(item, i)}>
                    <EmptyAlbum
                      i={item?._id}
                      title={item?.Album_Name}
                      photo={item?.photoslist?.length}
                      path={item?.photoslist[0]?.Photo_Path || ""}
                      type="album"
                      remove={handleRemove}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div>
                <EmptyAlbum title="No Album Found" photo="0" />
              </div>
            )}
          </div>
          <div className="mt-5">
            <p className="text-2xl">{activeAlbum?.name} Photos</p>
            <div>
              <span
                className="text-small text-blue-500 cursor-pointer"
                onClick={handleUpload}
              >
                Upload Photos
              </span>
              {activeAlbum?.name !== "Unsorted" && (
                <span
                  className="text-small text-blue-500 cursor-pointer ml-4"
                  onClick={() => {
                    setActiveAlbum({ ...activeAlbum, name: "Unsorted" });
                    fetchPhotos();
                  }}
                >
                  View Unsorted
                </span>
              )}
            </div>
            <div className="grid grid-cols-5 gap-4 mt-4">
              {activeAlbum?.Photos?.map((item, i) => (
                <img
                  key={i}
                  className="h-[200px] w-[200px] cursor-pointer object-cover"
                  src={item?.Photo_Path}
                  alt="photo path missing"
                  onClick={() => handlePreview(item)}
                />
              ))}
              {/* <div
                  key={i}
                  className="h-[200px] w-[200px] cursor-pointer"
                  onClick={() => handlePreview(item)}
                >
                  {activeAlbum?.name !== "Unsorted" ? (
                    <img
                      className="object-cover w-full h-full"
                      src={item?.Photo_Path}
                      alt="photo path missing"
                    />
                  ) : (
                    <img
                      className="object-cover w-full h-full"
                      src={item?.Photo_Path}
                      alt="photo path missing"
                    />
                  )}
                </div> */}
            </div>
          </div>

          {showModal && (
            <Modal setOpenModal={setShowModal} title="Create Photo Album">
              <label className="text-gray-600"> Album Name :</label>
              <input
                type="text"
                className="px-4 py-1 my-2 w-full border"
                value={data?.name}
                onChange={(e) => setData({ ...data, name: e.target.value })}
              />
              <label className="text-gray-600">
                {" "}
                Album Description (Optional):
              </label>
              <input
                type="text"
                className="px-4 py-1 my-2 w-full border"
                value={data?.des}
                onChange={(e) => setData({ ...data, des: e.target.value })}
              />
              <div className="flex justify-end">
                <button
                  className="px-4 py-2 bg-blue-500 rounded-sm my-3  text-white hover:bg-blue-700"
                  onClick={() => handleCreateAlbum()}
                >
                  Create Photo Album
                </button>
              </div>
            </Modal>
          )}
          {show && (
            <Modal setOpenModal={setShow} title="Upload Photo">
              <label className="text-gray-600"> Select Photo:</label>
              {imgfile?.picturePreview ? (
                <div className="w-full flex justify-center flex-col">
                  <img
                    src={imgfile?.picturePreview}
                    alt="photo"
                    className="h-[150px] w-[150px]"
                  />
                  <p
                    className="mt-3 cursor-pointer text-blue-500"
                    onClick={() => {
                      uploadimg({});
                    }}
                  >
                    {" "}
                    Remove and Choose new
                  </p>
                </div>
              ) : (
                <input
                  type="file"
                  className="px-4 py-1 my-2 w-full border"
                  accept=".jpg,.jpeg"
                  onChange={imgFilehandler}
                />
              )}

              <div className="flex justify-end">
                <button
                  className="px-4 py-2 bg-blue-500 rounded-sm my-3  text-white hover:bg-blue-700"
                  onClick={
                    activeAlbum?.name !== "Unsorted"
                      ? handleUploadPhotodirect
                      : handleUploadSave
                  }
                >
                  Save Photo
                </button>
              </div>
            </Modal>
          )}
          {preViewShow && (
            <Modal setOpenModal={setPreviewShow} title="Preview Photo">
              {preView?.Photo_Path ? (
                <div className="w-full flex justify-center flex-col mt-3">
                  <img
                    src={preView?.Photo_Path}
                    alt="photo"
                    className="h-[150px] w-[150px]"
                  />
                </div>
              ) : (
                <div className="w-full flex justify-center flex-col mt-3">
                  <img
                    src={preView?.Photo_Path}
                    alt="photo"
                    className="h-[150px] w-[150px]"
                  />
                </div>
              )}
              <div className="border-b pb-2 mt-4 h-[12px] w-full"></div>

              <div className="flex justify-end mt-4">
                <select
                  className=" border border-gray-300
                 text-gray-700 text-sm rounded-sm  px-4 bg-white"
                  //
                  onChange={(e) =>
                    handleAddphotoToalbum(preView?._id, e.target.value)
                  }
                >
                  <option defaultValue="">Move to Album</option>
                  {albumList?.length > 0 &&
                    albumList.map((item, i) => (
                      <option value={item?._id} key={i}>
                        {item?.Album_Name}
                      </option>
                    ))}
                </select>
                <button
                  className="px-4 py-2 bg-blue-500 rounded-md ml-3  text-white hover:bg-blue-700"
                  onClick={() =>
                    handleRemovePhoto(
                      activeAlbum?.name !== "Unsorted"
                        ? preView?.PhotoId
                        : preView?._id
                    )
                  }
                >
                  Remove
                </button>
              </div>
            </Modal>
          )}
          <ToastContainer />
        </div>
      )}
    </>
  );
}

export default PhotoHome;
