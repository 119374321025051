import React, { useState, useEffect, useContext } from "react";
import { getItem } from "../../utils/storage";
import { useLocation, useNavigate } from "react-router-dom";
import { getReqParamheader } from "../../services/apiCall";
import { API_URL_CONSTANT } from "../../constants/apiConstant";
import UpdateOrg from "../UpdateOrg";
import PageSnipper from "../../components/PageSnipper";
import { OrgContext } from "../../OrgContext";
import Moment from "react-moment";
import { formatDateInPDT } from "../../utils/helper";

function Dashboard() {
  const [people, setPeople] = useState([]);
  const [article, setArticle] = useState([]);
  const [emailData, setEmailData] = useState([]);
  const [userId, setUserId] = useState("");
  const [orgId, setOrgId] = useState("");
  const [org, setOrg] = useState("");
  const [orgData, setOrgData] = useState({});
  const [isDashbord, setIsDashboard] = useState(true);
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { organizationId } = useContext(OrgContext);

  var dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  useEffect(() => {
    let orgname = getItem("currentOrg");
    let userid = getItem("user_id");
    let orgid = path.split("/")[2];
    if (orgname && userid && orgid) {
      setOrg(orgname);
      setUserId(userid);
      setOrgId(orgid);
    }
  }, []);

  useEffect(() => {
    if (orgId) {
      setLoading(true);
      getArticleData(orgId);
      getContact();
      getCurrentOrg(orgId);
      getEmailStatus();
    }
  }, [orgId]);

  useEffect(() => {
    if (organizationId) {
      setOrgId(organizationId);
      let orgname = getItem("currentOrg");
      if (orgname) {
        setOrg(orgname);
      }
      // setLoading(true)
      // getArticleData(orgId)
      // getContact()
      // getCurrentOrg(orgId)
      // getEmailStatus()
    }
  }, [organizationId]);

  const handleViewContact = () => {
    navigate(`${path}/contact`);
  };
  const handleViewArticle = () => {
    navigate(`${path}/article`);
  };
  const createArticle = () => {
    navigate(`${path}/article/create`);
  };
  const createEmail = () => {
    navigate(`${path}/email/create`);
  };
  const handleViewEmail = () => {
    navigate(`${path}/email`);
  };

  const getArticleData = (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/recent_article_details/${id}/${userId}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("get article list response", res?.data);
          let filter = res?.data?.data?.filter(
            (item) => item?.Document_status === "Published"
          );
          if (filter?.length > 3) {
            setArticle(filter.slice(-3));
          } else {
            setArticle(filter);
          }
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const getContact = () => {
    let url = `${API_URL_CONSTANT.baseUrl}/recent_contact_details/${orgId}/${userId}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("get contact details response", res?.data);
          let data = res?.data?.data;
          if (data?.length > 3) {
            setPeople(data.slice(-3));
          } else {
            setPeople(data);
          }
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const getCurrentOrg = (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/Organization_details/${id}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("get org dedtails response", res?.data);
          setOrgData(res?.data?.data);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const getEmailStatus = () => {
    getAllEmailList();
  };
  const getAllEmailList = () => {
    let url = `${API_URL_CONSTANT.baseUrl}/recent_email_details/${orgId}/${userId}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("get email list response", res?.data);
          let filter = res?.data?.data?.filter(
            (item) => item?.Email_status === "Sent"
          );
          if (filter?.length > 3) {
            setEmailData(filter.slice(-3));
          } else {
            setEmailData(filter);
          }
          setLoading(false);
        } else {
          setLoading(false);
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  return loading ? (
    <PageSnipper loading={loading} />
  ) : (
    <div className="px-6">
      <div className="flex justify-between">
        <p
          className={
            isDashbord
              ? "text-2xl text-blue-500"
              : "text-2xl cursor-pointer hover:text-blue-500"
          }
          onClick={() => setIsDashboard(isDashbord ? isDashbord : !isDashbord)}
        >
          {org} Dashboard <br />
          {!isDashbord ? (
            <div style={{ fontSize: "12px" }}> &#x25c0; Back to Dashboard</div>
          ) : (
            ""
          )}
        </p>
        <p
          className={
            !isDashbord
              ? "text-1xl text-blue-500"
              : "text-1xl cursor-pointer hover:text-blue-500"
          }
          onClick={() => setIsDashboard(!isDashbord ? isDashbord : !isDashbord)}
        >
          Edit Organization Profile{" "}
        </p>
      </div>
      {isDashbord ? (
        <div className="flex justify-between items-start w-full">
          <div className="w-[49%]">
            <div className="my-3 border rounded-md bg-white">
              <div className="px-6 py-3 border-b flex justify-between items-center">
                <div className="text-1xl bold">New People</div>
                <div className="flex gap-4">
                  <button
                    className="px-3 py-1 border rounded-md bg-white cursor-pointer
                         text-gray-500 text-sm hover:text-blue-500 hover:border-blue-500"
                    onClick={handleViewContact}
                  >
                    {" "}
                    View All{" "}
                  </button>
                  {/* <button className='px-3 py-1 border rounded-md
                        bg-blue-500 cursor-pointer hover:bg-blue-700   text-white'
                                onClick={createContact}
                            > Create New  </button> */}
                </div>
              </div>

              <div className="px-6 py-3">
                {people?.length > 0 ? (
                  <div>
                    {people.map((item, i) => (
                      <div key={i}>
                        <div className="py-3 tex-sm text-gray-600">
                          <p>{item?.Email} </p>

                          <p>
                            Added at :
                            <Moment format="DD-MMM-YYYY">
                              {item?.createdAt}
                            </Moment>
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-600"> No Contact Found </p>
                )}
              </div>
            </div>
            <div className="my-3 border rounded-md bg-white">
              <div className="px-6 py-3 border-b flex justify-between items-center">
                <div className="text-1xl bold">Recent Articles</div>
                <div className="flex gap-4">
                  <button
                    className="px-3 py-1 border rounded-md bg-white cursor-pointer
                         text-gray-500 text-sm hover:text-blue-500 hover:border-blue-500"
                    onClick={handleViewArticle}
                  >
                    {" "}
                    View All{" "}
                  </button>
                  <button
                    className="px-3 py-1 border rounded-md bg-blue-500 cursor-pointer hover:bg-blue-700
                          text-white"
                    onClick={createArticle}
                  >
                    {" "}
                    Create New{" "}
                  </button>
                </div>
              </div>

              <div className="px-6 py-3">
                {article?.length > 0 ? (
                  <div>
                    {article.map((item, i) => (
                      <div key={i}>
                        <div className="py-3 tex-sm text-gray-600">
                          <p>{item?.draft?.Title} </p>
                          <span>Published at:&nbsp;</span>
                          <Moment format="DD-MMM-YYYY">
                            {item?.lastAccessedAt?.time}
                          </Moment>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-500 text-sm">
                    {" "}
                    You have no published articles. Try creating and publishing
                    one!{" "}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="w-[49%]">
            <div className="my-3 border rounded-md bg-white">
              <div className="px-6 py-3 border-b flex justify-between items-center">
                <div className="text-1xl bold">Recent Emails</div>
                <div className="flex gap-4">
                  <button
                    className="px-3 py-1 border rounded-md bg-white cursor-pointer
                         text-gray-500 text-sm hover:text-blue-500 hover:border-blue-500"
                    onClick={handleViewEmail}
                  >
                    View All
                  </button>
                  <button
                    className="px-3 py-1 border rounded-md bg-blue-500 cursor-pointer hover:bg-blue-700
                          text-white"
                    onClick={createEmail}
                  >
                    Create New
                  </button>
                </div>
              </div>

              <div className="px-6 py-3">
                {emailData?.length > 0 ? (
                  <div>
                    {emailData.map((item, i) => (
                      <div key={i}>
                        <div className="py-3 tex-sm text-gray-600">
                          <p>{item?.Subject} </p>
                          <p>
                            Sent at :&nbsp;
                            {formatDateInPDT(item?.lastAccessedAt?.time, true)}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-500 text-sm">
                    {" "}
                    You have not sent an email yet. Try creating and sending one
                    !{" "}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center">
          <div className="w-full">
            <UpdateOrg
              orgId={orgId}
              data={orgData}
              setIsDashboard={setIsDashboard}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
