import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "../components/Modal";
import { getItem } from "../utils/storage";
import {
  getReqParamheader,
  postReqParamheader,
  deleteReqParamheader,
  postReqParamheaderFile,
  patchReqParam,
  getReqParam,
} from "../services/apiCall";
import { API_URL_CONSTANT } from "../constants/apiConstant";
import { ToastContainer, toast } from "react-toastify";
import PageSnipper from "../components/PageSnipper";
import BaseTabs from "../components/BaseTabs";
import samplefile from "../assests/newContact.xlsx";
import axios from "axios";
import fileDownload from "js-file-download";
import BeatLoader from "react-spinners/BeatLoader";

function ContactHome() {
  const [contact, setContact] = useState([]);
  const [data, setData] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
  });
  const [searchValue, setSearchValue] = useState("");
  const [activeCard, setActiveCard] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    Mphone: "",
    Wphone: "",
    Company: "",
    JT: "",
    Address: "",
    City: "",
    State: "",
    Zip: "",
    Country: "",
  });
  const [orgId, setOrg] = useState("");
  const [selectedCustomList, setSelectedCustomList] = useState("");
  const [list, setList] = useState([]);
  const [userId, setUser] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [file, uploadimg] = useState("");
  const [activeTab, setActiveTab] = useState("General");
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [contactloading, setContactLoading] = useState(false);
  const override: CSSProperties = {
    display: "inline-block",
    margin: "0 auto",
    borderColor: "blue",
    verticalAlign: "center",
  };

  useEffect(() => {
    let userid = getItem("user_id");
    let orgid = path.split("/")[2];
    setOrg(orgid);
    setUser(userid);
  }, []);

  useEffect(() => {
    if (orgId && userId) {
      getContact();
    }
  }, [orgId]);
  useEffect(() => {
    if (searchValue) {
      const newPacientes = contact.filter(
        (value) =>
          value.FirstName.toLowerCase().includes(searchValue.toLowerCase()) ||
          value.Email.toLowerCase().includes(searchValue.toLowerCase())
      );
      setContact(newPacientes);
    } else {
      if (orgId && userId) {
        getContact();
      }
    }
  }, [searchValue]);

  const handleActive = async (item) => {
    // setActiveCard(item)
    setContactLoading(true);
    let id = item?._id;
    await getContactbyId(id);
  };
  const getCustomContact = (toggle) => {
    // setLoading(true)
    let url = `${API_URL_CONSTANT.baseUrl}/customlist_details/${orgId}/${userId}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          // console.log('get custom contact details response', res?.data)
          // setLoading(false)
          let deleted = res?.data?.data.filter(
            (item) => item?.is_deleted !== true
          );
          setList(deleted);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        // setLoading(false)
        setTimeout(() => {
          toast.error(err?.response?.data?.message);
        }, 1000);
      });
  };
  const getContactbyId = async (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/contact_detailsbyid/${id}/${orgId}/${userId}`;
    let res = await getReqParamheader(url);
    if (res) {
      if (res?.status) {
        // console.log('get contact details by id response', res?.data?.data)
        setActiveCard({
          ...activeCard,
          FirstName: res?.data?.data?.FirstName,
          LastName: res?.data?.data?.LastName,
          Email: res?.data?.data?.Email,
          Mphone: res?.data?.data?.Mobile_Phone || "",
          Wphone: res?.data?.data?.Work_Phone || "",
          Company: res?.data?.data?.Company || "",
          JT: res?.data?.data?.Job_Title,
          Address: res?.data?.data?.Address,
          City: res?.data?.data?.City,
          State: res?.data?.data?.State,
          Zip: res?.data?.data?.Zip,
          Country: res?.data?.data?.Country,
          _id: res?.data?.data?._id,
        });
        setShowContact(!showContact);
        setContactLoading(false);
        // getCustomContact(true)
      } else {
        console.log("response err", res?.message);
      }
    } else {
      console.log("response err", res?.message);
      //console.log('err', err)
      toast.error("Unable to open at this time");
    }
  };
  const getUniqueListBy = (arr, key) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  };

  const getContact = () => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/contact_details/${orgId}/${userId}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          // console.log('get contact details response', res?.data)
          let a = res?.data?.data;
          let uniqueArray = getUniqueListBy(a, "Email");
          // console.log('unique:::', uniqueArray)
          setContact(a);
          setLoading(false);
          getCustomContact(false);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };
  const handleCreateContact = () => {
    if (data?.FirstName && data?.LastName && data?.Email) {
      setShowModal(!showModal);
      setData({});
      addContact(data);
    } else {
      alert("Please fill all field..");
    }
  };
  const handleUpdateContact = () => {
    let update = {
      FirstName: activeCard?.FirstName,
      LastName: activeCard?.LastName,
      // Email: activeCard?.Email,
      Work_Phone: activeCard?.Wphone,
      Mobile_Phone: activeCard?.Mphone,
      Company: activeCard?.Company,
      Job_Title: activeCard?.JT,
      Address: activeCard?.Address,
      City: activeCard?.City,
      State: activeCard?.State,
      Zip: activeCard?.Zip,
      Country: activeCard?.Country,
    };
    //console.log(activeCard)
    let id = activeCard?._id;
    if (activeCard?.FirstName && activeCard?.LastName) {
      // setShowContact(false)
      updateContact(id, update);
      // setActiveCard({})
    } else {
      alert("Please fill all required details..");
    }
  };
  const handleTab = (txt) => {
    setActiveTab(txt);
  };
  const addContact = (data) => {
    let url = `${API_URL_CONSTANT.baseUrl}/add_a_contact/${orgId}/${userId}`;
    postReqParamheader(url, data)
      .then((res) => {
        if (res?.status) {
          // console.log('add contact response', res?.data)
          getContact();
          toast.success(res?.data?.message);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  const updateContact = (id, data) => {
    let url = `${API_URL_CONSTANT.baseUrl}/updatecontact/${id}/${orgId}/${userId}`;
    patchReqParam(url, data)
      .then((res) => {
        if (res?.status) {
          // console.log('update contact response', res?.data)
          //getContact()
          //toast.success(res?.data?.message)
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  const handleCustom = () => {
    navigate(`${path}/custom`);
  };
  const remove = (id) => {
    let a = window.confirm("Are you sure you want to delete this contact?");
    if (a) {
      setShowContact(false);
      handleRemove(id);
    }
  };
  const handleRemove = (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/deletecontact/${id}/${orgId}/${userId}`;
    deleteReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          // console.log('delete custom response', res?.data)
          getContact();
          toast.success(res?.data?.message);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  const handleSearch = (key) => {
    setSearchValue(key);
  };
  const handleCreateCustom = () => {
    navigate(`/${path}/custom/create`);
  };
  const handleDownloadSample = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };
  const handleDownload = () => {
    let filename = "Contact.xlsx";
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/get-exported-contacts/${orgId}/${userId}`;
    getReqParam(url, {
      // responseType: 'blob',
    })
      .then((blob) => {
        setLoading(false);
        // console.log('get export response', blob)
        //fileDownload(res.data, filename)

        // 2. Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        setTimeout(() => {
          toast.error("Sorry,Unable to export this file right this moment");
        }, 1000);
      });
  };
  const Filehandler = (e) => {
    if (e.target.files.length !== 0) {
      uploadimg({
        picturePreview: URL.createObjectURL(e.target.files[0]),
        pictureAsFile: e.target.files[0],
      });
    }
  };
  const saveImport = () => {
    let data = {};
    if (file?.pictureAsFile) {
      data.files = file?.pictureAsFile;
      uploadContact(data);
    } else {
      alert("please upload file....");
    }
  };
  const uploadContact = (data) => {
    setLoading(true);
    let url = "";
    if (selectedCustomList) {
      // alert(selectedCustomList)
      url = `${API_URL_CONSTANT.baseUrl}/import-contacts/${orgId}/${userId}?customListName=${selectedCustomList}`;
    } else {
      url = `${API_URL_CONSTANT.baseUrl}/import-contacts/${orgId}/${userId}`;
    }

    postReqParamheaderFile(url, data)
      .then((res) => {
        setLoading(false);
        // console.log('save import response', res)
        setShowImport(false);
        toast.success(res?.data?.message);
        setSelectedCustomList("");
        setTimeout(() => {
          getContact();
        }, 4000);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        setTimeout(() => {
          toast.error(err?.response?.data?.message);
        }, 2000);
      });
  };
  const handleSwich = (item, id, cid) => {
    let arr = item?.Members?.filter((item) => item?.memberId === id);
    if (arr?.length > 0) {
      // handleRemoveContact(id)
      handleRemoveContact(item?._id);
      // alert('remove')
    } else {
      handleAddContact(item?._id);
      // alert('add')
    }
  };
  const handleAddContact = (id) => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/add_to_customlist/${activeCard?._id}/${orgId}/${userId}/${id}`;
    postReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          // console.log('add contact in custom response', res?.data)
          setLoading(false);
          getCustomContact(false);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };
  const handleRemoveContact = (id) => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/remove_from_customlist/${activeCard?._id}/${orgId}/${userId}/${id}`;
    deleteReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          // console.log('removecontact in custom response', res?.data)
          setLoading(false);
          getCustomContact(false);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };
  const handleCheck = (item, id) => {
    let arr = item?.Members?.filter((item) => item?.memberId === id);
    if (arr?.length > 0) {
      return true;
    }
    return false;
  };

  return (
    <>
      {loading ? (
        <PageSnipper loading={loading} />
      ) : (
        <div className="px-6">
          <div className="flex items-center gap-4">
            <p className="text-2xl flex-1">Contacts ({contact.length})</p>
            <input
              type="text"
              placeholder="Search by name or email"
              className="border rounded-md bg-white px-4 py-2 w-[40%]"
              onChange={(e) => handleSearch(e.target.value)}
            />
            <button
              className="bg-blue-500 text-white px-4 py-2 border rounded-md hover:bg-blue-800"
              onClick={() => setShowModal(!showModal)}
            >
              Add a Contact
            </button>
          </div>
          <div className="flex gap-2">
            <p
              className="mt-3 text-blue-500 hover:text-blue-700 cursor-pointer"
              onClick={handleCustom}
            >
              {" "}
              Custom Lists |{" "}
            </p>

            <p
              className="mt-3 text-blue-500 hover:text-blue-700 cursor-pointer"
              onClick={() => handleDownload()}
            >
              Export |
            </p>
            <p
              className="mt-3 text-blue-500 hover:text-blue-700 cursor-pointer"
              onClick={() => setShowImport(!showImport)}
            >
              {" "}
              Import{" "}
            </p>
          </div>
          {contactloading && (
            <div className="flex">
              <p className="w-full" style={{ float: "right" }}>
                <em>Loading the Contact &nbsp;&nbsp;&nbsp;</em>
                <BeatLoader
                  color={"#220099"}
                  loading={true}
                  cssOverride={override}
                  size={20}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </p>
            </div>
          )}

          <div className="grid grid-cols-3 gap-4 mt-3">
            {contact.length > 0 ? (
              contact.map((item, i) => (
                <div
                  key={i}
                  className="border rounded-md bg-white px-6 py-4 text-gray-700 cursor-pointer hover:border-2"
                >
                  <div
                    className="flex justify-start gap-4"
                    onClick={() => {
                      handleActive(item);
                    }}
                  >
                    <div className="border border-gray-400  w-12 h-12 bg-gray-100 rounded-full flex justify-center items-center">
                      <div>
                        {" "}
                        {item?.FirstName?.split("")[0].toUpperCase() || "M"}
                      </div>
                    </div>
                    <div className="">
                      <div className="flex gap-1">
                        <p className="text-md text-gray-600">
                          {" "}
                          {item?.FirstName}
                        </p>
                        <p className="text-md text-gray-600">
                          {" "}
                          {item?.LastName}
                        </p>
                      </div>

                      <p className="text-sm text-gray-600"> {item?.Email}</p>
                      <p className="text-sm text-gray-600"> {item?.Status}</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center">No Custom Contact list found</div>
            )}
          </div>
          {showModal && (
            <Modal setOpenModal={setShowModal} title="Create a New Profile">
              <label className="text-gray-600"> First Name*:</label>
              <input
                type="text"
                className="px-4 py-1 my-2 w-full border"
                value={data?.FirstName}
                onChange={(e) =>
                  setData({ ...data, FirstName: e.target.value })
                }
              />
              <label className="text-gray-600"> Last Name*:</label>
              <input
                type="text"
                className="px-4 py-1 my-2 w-full border"
                value={data?.LastName}
                onChange={(e) => setData({ ...data, LastName: e.target.value })}
              />
              <label className="text-gray-600"> Email*:</label>
              <input
                type="text"
                required
                className="px-4 py-1 my-2 w-full border"
                value={data?.Email}
                onChange={(e) => setData({ ...data, Email: e.target.value })}
              />

              <button
                className="px-4 py-2 bg-blue-500 rounded-sm my-3 w-full text-white hover:bg-blue-700"
                onClick={handleCreateContact}
              >
                {" "}
                Create a profile{" "}
              </button>
            </Modal>
          )}
          {showImport && (
            <Modal setOpenModal={setShowImport} title="Import Contact">
              <div className="my-3">
                <p className="text-sm text-gray-600 py-3">
                  {" "}
                  Please follow given sample file format...
                  <span
                    className="text-blue-500 cursor-pointer hover:text-blue-700"
                    onClick={() =>
                      handleDownloadSample(samplefile, "SampleContactList.xlsx")
                    }
                  >
                    Download Sample
                  </span>
                </p>
                <input
                  type="file"
                  accept=".xlsx"
                  onChange={Filehandler}
                  placeholder="upload contact"
                  className="py-3"
                />
                <div className="my-3">
                  <label className="block mb-2">Select Custom List:</label>
                  {list?.length > 0 ? (
                    <div>
                      <select
                        className=" border border-gray-300 text-gray-700 text-sm rounded-sm w-full px-2 py-2 bg-white"
                        onChange={(e) => setSelectedCustomList(e.target.value)}
                      >
                        <option value=""> select</option>
                        {list.map((item, i) => (
                          <option value={item?.Team_Group_Name} key={i}>
                            {" "}
                            {item?.Team_Group_Name}{" "}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : (
                    <div className="text-center text-sm text-gray-600">
                      No Contact list found
                    </div>
                  )}
                </div>
                <div className="w-full flex justify-end">
                  <button
                    className="px-4 py-2 bg-blue-500 rounded-md  text-white hover:bg-blue-700"
                    onClick={saveImport}
                  >
                    {" "}
                    Save
                  </button>
                </div>
              </div>
            </Modal>
          )}
          {showContact && (
            <Modal
              setOpenModal={setShowContact}
              item={activeCard}
              type="contact"
              remove={remove}
            >
              <div className="my-4">
                <BaseTabs
                  active={activeTab}
                  handleTab={handleTab}
                  tabList={["General", "Contact Info", "Custom Lists"]}
                />
              </div>
              {activeTab === "General" ? (
                <div>
                  <label className="text-gray-600"> Email :</label>
                  <input
                    type="text"
                    className="px-4 py-1 my-2 w-full border"
                    value={activeCard?.Email}
                    disabled
                    onChange={(e) =>
                      setActiveCard({ ...activeCard, Email: e.target.value })
                    }
                  />
                  <label className="text-gray-600"> First Name:</label>
                  <input
                    type="text"
                    className="px-4 py-1 my-2 w-full border"
                    value={activeCard?.FirstName}
                    onChange={(e) =>
                      setActiveCard({
                        ...activeCard,
                        FirstName: e.target.value,
                      })
                    }
                  />
                  <label className="text-gray-600"> Last Name:</label>
                  <input
                    type="text"
                    className="px-4 py-1 my-2 w-full border"
                    value={activeCard?.LastName}
                    onChange={(e) =>
                      setActiveCard({ ...activeCard, LastName: e.target.value })
                    }
                  />
                  <div className="flex justify-end">
                    <button
                      className="px-6 py-2 bg-blue-600 rounded-md my-3 text-white hover:bg-blue-500"
                      onClick={() => {
                        handleUpdateContact();
                        setActiveTab("Contact Info");
                      }}
                    >
                      {" "}
                      Next{" "}
                    </button>
                  </div>
                </div>
              ) : activeTab === "Contact Info" ? (
                <div>
                  <label className="text-gray-600"> Mobile Phone:</label>
                  <input
                    type="text"
                    className="px-4 py-1 my-2 w-full border"
                    value={activeCard?.Mphone}
                    onChange={(e) =>
                      setActiveCard({ ...activeCard, Mphone: e.target.value })
                    }
                  />
                  <label className="text-gray-600"> Work Phone:</label>
                  <input
                    type="text"
                    className="px-4 py-1 my-2 w-full border"
                    value={activeCard?.Wphone}
                    onChange={(e) =>
                      setActiveCard({ ...activeCard, Wphone: e.target.value })
                    }
                  />
                  <label className="text-gray-600"> Company:</label>
                  <input
                    type="text"
                    className="px-4 py-1 my-2 w-full border"
                    value={activeCard?.Company}
                    onChange={(e) =>
                      setActiveCard({ ...activeCard, Company: e.target.value })
                    }
                  />
                  <label className="text-gray-600"> Job Title:</label>
                  <input
                    type="text"
                    className="px-4 py-1 my-2 w-full border"
                    value={activeCard?.JT}
                    onChange={(e) =>
                      setActiveCard({ ...activeCard, JT: e.target.value })
                    }
                  />
                  <label className="text-gray-600"> Address:</label>
                  <input
                    type="text"
                    className="px-4 py-1 my-2 w-full border"
                    value={activeCard?.Address}
                    onChange={(e) =>
                      setActiveCard({ ...activeCard, Address: e.target.value })
                    }
                  />
                  <label className="text-gray-600"> City:</label>
                  <input
                    type="text"
                    className="px-4 py-1 my-2 w-full border"
                    value={activeCard?.City}
                    onChange={(e) =>
                      setActiveCard({ ...activeCard, City: e.target.value })
                    }
                  />
                  <label className="text-gray-600"> State:</label>
                  <input
                    type="text"
                    className="px-4 py-1 my-2 w-full border"
                    value={activeCard?.State}
                    onChange={(e) =>
                      setActiveCard({ ...activeCard, State: e.target.value })
                    }
                  />
                  <label className="text-gray-600"> Zip:</label>
                  <input
                    type="text"
                    className="px-4 py-1 my-2 w-full border"
                    value={activeCard?.Zip}
                    onChange={(e) =>
                      setActiveCard({ ...activeCard, Zip: e.target.value })
                    }
                  />
                  <label className="text-gray-600"> Country:</label>
                  <input
                    type="text"
                    className="px-4 py-1 my-2 w-full border"
                    value={activeCard?.Country}
                    onChange={(e) =>
                      setActiveCard({ ...activeCard, Country: e.target.value })
                    }
                  />
                  <div className="flex justify-between">
                    <button
                      className="px-6 py-2 bg-gray-100 border rounded-md my-3 text-gray hover:border-blue-500 hover:text-blue-500"
                      onClick={() => {
                        setActiveTab("General");
                      }}
                    >
                      {" "}
                      Back{" "}
                    </button>

                    <button
                      className="px-6 py-2 bg-blue-600 rounded-md my-3 text-white hover:bg-blue-500"
                      onClick={() => {
                        setActiveTab("Custom Lists");
                        handleUpdateContact();
                      }}
                    >
                      {" "}
                      Next{" "}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="pb-4">
                  {list?.length > 0 ? (
                    <div>
                      <p className="text-sm text-gray-600 pb-2">
                        {" "}
                        Assign this contact to custom lists:
                      </p>
                      {list.map((item, i) => (
                        <div key={i} className="flex">
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              handleSwich(item, activeCard?._id, e.target.value)
                            }
                            value={item?._id}
                            checked={handleCheck(item, activeCard?._id)}
                          />
                          <p className="text-sm pl-4">
                            {" "}
                            {item?.Team_Group_Name}{" "}
                          </p>
                        </div>
                      ))}
                      <p className="text-sm text-blue-500 cursor-pointer pt-2">
                        <Link to={`${path}/custom/create`}>
                          Create A New Custom List
                        </Link>
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p className="text-sm text-gray-600">
                        {" "}
                        You do not have any custom lists. You can can create one
                        using the link below.
                      </p>
                      <p className="text-sm text-blue-500 cursor-pointer">
                        <Link to={`${path}/custom/create`}>
                          Create A New Custom List
                        </Link>
                      </p>
                    </div>
                  )}

                  <div className="flex justify-start my-4">
                    <button
                      className="px-6 py-2 bg-gray-100 border rounded-md my-3 text-gray hover:border-blue-500 hover:text-blue-500"
                      onClick={() => {
                        setActiveTab("Contact Info");
                      }}
                    >
                      {" "}
                      Back{" "}
                    </button>
                  </div>
                </div>
              )}
            </Modal>
          )}
          <ToastContainer />
        </div>
      )}
    </>
  );
}

export default ContactHome;
