import React from "react";
import Moment from "react-moment";

const HtmlToReactParser = require("html-to-react").Parser;
const htmlToReactParser = new HtmlToReactParser();

export default function Article({ item, orgname, handleClickArticle }) {
  // let image = "images/no-image-placeholder.png";
  let image = process.env.PUBLIC_URL + "/images/no-image-placeholder.png";
  if (item?.draft?.Featured_Photo) {
    image = item?.draft?.Featured_Photo;
  }

  return (
    <div
      className="border rounded-md bg-white text-gray-700 cursor-pointer shadow transition duration-300 ease-in-out hover:bg-gray-100 hover:border-blue-500"
      onClick={() => handleClickArticle(item?._id)}
    >
      <div className="flex justify-start items-center p-3">
        <div className="border border-gray-400 w-12 h-12 bg-gray-100 rounded-full flex justify-center items-center">
          <div>{orgname?.charAt(0).toUpperCase() || "M"}</div>
        </div>
        <div className="ml-4">
          <p className="text-xl text-black">{item?.org_name ?? orgname}</p>
          <p className="text-sm text-gray-600">
            <span>Published on&nbsp;</span>
            <Moment format="DD-MMM-YYYY">{item?.lastAccessedAt?.time}</Moment>
          </p>
        </div>
      </div>

      <img className="h-64 w-full object-cover" src={image} alt="" />

      <div className="p-3">
        <p className="text-xl text-black">{item?.draft?.Title}</p>
        <p className="text-sm text-gray-600 break-words">
          {item?.draft?.Content?.length > 100 ? (
            <>
              {htmlToReactParser.parse(item?.draft?.Content?.slice(0, 100))}
              <a>Read more...</a>
            </>
          ) : (
            htmlToReactParser.parse(item?.draft?.Content)
          )}
        </p>
      </div>
    </div>
  );
}
