import React, { useEffect } from 'react'

function ArticleComponent({ data, id, hs, hf, ts, tf, type, logow, logoh, read }) {

    const HtmlToReactParser = require('html-to-react').Parser;
    const htmlToReactParser = new HtmlToReactParser();
    let desL = htmlToReactParser.parse(data?.Content?.slice(0, 300));
    let desM = htmlToReactParser.parse(data?.Content);
    let title = htmlToReactParser.parse(data?.Title);
    let ed = htmlToReactParser.parse(data?.Event_Description);
    let ed_excerpt = htmlToReactParser.parse(data?.Event_Description?.slice(0, 300));
    let url = `https://smorgborg.org/view/${id}`


    // useEffect(() => {
    //     //set image params here
    //     //if(imgWidth === '0'){
    //     console.log("Article here")
    //     console.log(logoh);
    // }, []);

    var newDateOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    }

    return (
        <>
            {
                type && type === 'event' ?
                    <table border="0" cellSpacing="0" width="100%" style={{ marginTop: "8px", marginBottom: "8px" }}>
                        <tbody>
                            <tr>
                                <td width="600" align="left">
                                    <div align="left" style={{ textAlign: 'left', width: '100%' }}>

                                        <p align="left" style={{ fontSize: `${hs}`, fontFamily: `${hf}`, textAlign: "left" }}>
                                            <a href={data?.Event_Website} target='_blank' style={{ textDecoration: 'none' }}>{data?.Event_Name}</a>
                                        </p>
                                        {
                                            (logow && logoh) ?
                                                data?.Featured_Photo &&
                                                <table border="0" cellSpacing="0" width="100%">
                                                    <tr>
                                                        <td width="600" align="center">
                                                            <a target="_blank" href={data?.Event_Website}><img src={data?.Featured_Photo} width={logow} height={logoh} style={{ width: logow + "px", height: logoh + "px", maxHeight: "600px", maxWidth: "600px", display: "block", margin: "0px auto" }} alt="feature photo" /></a>
                                                        </td>
                                                    </tr>
                                                </table>
                                                :
                                                data?.Featured_Photo &&
                                                <div style={{ width: '100%', textAlign: '-webkit-center' }} >
                                                    <img src={data?.Featured_Photo} alt="feature photo" style={{ maxHieght: '600px', maxWidth: '600px' }} />
                                                </div>
                                        }
                                        <table cellPadding="0" cellSpacing="0" width="100%">
                                            <tbody>
                                                <tr>
                                                    <td align="left">
                                                        <p align="left" style={{ textAlign: "left", fontSize: `${ts}`, fontFamily: `${tf}` }}>{data?.Event_Start_Date.toLocaleString("en-US", newDateOptions)}</p>
                                                        <p align="left" width="600" style={{ textAlign: "left", fontSize: `${ts}`, fontFamily: `${tf}`, width: "600px", wordWrap: "break-word" }}>
                                                        {
                                                            read ?
                                                                ed_excerpt
                                                                :
                                                                ed  
                                                        }
                                                        {
                                                            read ?
                                                                <a href={data?.Event_Website} target='_blank' >...Read more</a>
                                                                :
                                                                null
                                                        }
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    :
                    <table border="0" cellSpacing="0" width="100%" style={{ marginTop: "8px", marginBottom: "8px" }}>
                        <tbody>
                            <tr>
                                <td width="600" align="left">

                                    <div align="left" style={{ textAlign: 'left', width: '100%' }}>

                                        <p style={{ fontSize: `${hs}`, fontFamily: `${hf}` }}>
                                            <a href={url} target='_blank' style={{ textDecoration: 'none' }}>{title}</a>

                                        </p>
                                        {
                                            (logow && logoh) ?
                                                data?.Featured_Photo &&
                                                <table border="0" cellSpacing="0" width="100%">
                                                    <tr>
                                                        <td width="600" align="center">
                                                            <a target="_blank" href={url}><img src={data?.Featured_Photo} width={logow} height={logoh} style={{ width: logow + "px", height: logoh + "px", maxHeight: "600px", maxWidth: "600px", display: "block", margin: "0px auto" }} alt="feature photo" /></a>
                                                        </td>
                                                    </tr>
                                                </table>
                                                :
                                                data?.Featured_Photo &&
                                                <div style={{ width: '100%', textAlign: '-webkit-center' }} >
                                                    <a target="_blank" href={url}><img src={data?.Featured_Photo} alt="feature photo" style={{ maxHieght: '600px', maxWidth: '600px' }} /></a>
                                                </div>
                                        }

                                        <table cellPadding="0" cellSpacing="0" width="100%">
                                            <tbody>
                                                <tr>
                                                    <td align="left">
                                                        <div align="left" style={{ textAlign: "left", fontSize: `${ts}`, fontFamily: `${tf}` }}>
                                                            <div >
                                                                {
                                                                    read ?
                                                                        desL
                                                                        :
                                                                        desM
                                                                }
                                                                {
                                                                    read ?
                                                                        <a href={url} target='_blank' style={{ textDecoration: 'none' }}  >..... Read more</a>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
            }

        </>

    )
}

export default ArticleComponent
