import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BaseTable from "../components/BaseTable";
import {
  getReqParamheader,
  deleteReqParamheader,
  postReqParamheader,
  postReqParamheaderFile,
} from "../services/apiCall";
import { getItem } from "../utils/storage";
import { API_URL_CONSTANT } from "../constants/apiConstant";
import { ToastContainer, toast } from "react-toastify";
import PageSnipper from "../components/PageSnipper";

function EmailHome() {
  const [emailList, setEmailList] = useState([]);
  const [orgId, setOrg] = useState("");
  const [userId, setUser] = useState("");
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let userid = getItem("user_id");
    let orgid = path.split("/")[2];
    setOrg(orgid);
    setUser(userid);
  }, []);

  useEffect(() => {
    if (orgId) {
      getAllEmailList();
    }
  }, [orgId]);

  const getAllEmailList = () => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/emaildetails/${orgId}/${userId}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("get email list response", res?.data);
          setEmailList(res?.data?.data);
          setLoading(false);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const handleDelete = (id) => {
    let a = window.confirm("Are you sure you want to delete this email?");
    if (a) {
      setLoading(true);
      let url = `${API_URL_CONSTANT.baseUrl}/deleteEmails/${id}/${orgId}/${userId}`;
      deleteReqParamheader(url)
        .then((res) => {
          if (res?.status) {
            console.log("delete email by id  response", res?.data);
            getAllEmailList();
            setLoading(false);
            toast.success(res?.data?.message);
          } else {
            console.log("response err", res?.message);
          }
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
          toast.error(err?.response?.data?.message);
        });
    }
  };
  const handleRestore = (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/restoreEmails/${id}/${orgId}/${userId}`;
    postReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("restore email by id response", res?.data);
          getAllEmailList();

          toast.success(res?.data?.message);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  const handleResend = (id) => {
    getByIdEmail(id);
  };
  const getByIdEmail = (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/emaildetailsbyId/${id}/${orgId}/${userId}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log(
            "get email template details by id response",
            res?.data?.data?.sent_email_recipents
          );
          let email = res?.data?.data?.sent_email_recipents.join(",");
          // console.log(email)
          if (email) {
            let data = {};
            data.sent_email_recipents = email;
            sendEmailToUser(data, id);
          }
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  const sendEmailToUser = (data, id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/sentMailtoaddress/${id}/${orgId}/${userId}`;
    postReqParamheaderFile(url, data)
      .then((res) => {
        if (res?.status) {
          console.log("sent email response", res?.data);
          toast.success(res?.data?.message);
        } else {
          //console.log('response err', res?.message)
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };

  const handleEdit = (id) => {
    // console.log('handle edit::::', id, path)
    navigate(`${path}/${id}`);
    // alert('Edit Email Integration in Progress...')
    // getByIdEmail(id)
  };
  const handleView = (id) => {
    // navigate(`${path}/${id}`)
    alert("View Template in progress...");
  };

  const handleViewStats = (id) => {
    navigate(`${path}/${id}/stats`);
  };

  return (
    <>
      {loading ? (
        <PageSnipper loading={loading} />
      ) : (
        <div className="px-6">
          <div className="flex justify-between mb-3">
            <p className="text-2xl">Emails ({emailList.length}) </p>
            <p className="text-small text-blue-500 mt-2 cursor-pointer mb-4">
              <Link to={`${path}/create`}>Create an Email</Link>
            </p>
          </div>
          {emailList?.length > 0 ? (
            <div>
              <BaseTable
                header={["Subject", "Email Status", "Last Accessed", "Actions"]}
                data={emailList}
                remove={handleDelete}
                edit={handleEdit}
                restore={handleRestore}
                resend={handleResend}
                view={handleView}
                viewStats={handleViewStats}
                type="email"
              />
            </div>
          ) : (
            <div className="w-full text-center">No Emails found</div>
          )}
          <ToastContainer />
        </div>
      )}
    </>
  );
}

export default EmailHome;
