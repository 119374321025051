// import React from "react";
// const CHARCOAL = "#606060";
// const styles = {
//   block: {
//     boxSizing: "border-box",
//     display: "inline-block",
//     height: "100%",
//     padding: "24px",
//     position: "relative",
//     verticalAlign: "top",
//     width: "50%",
//   },
//   center: {
//     left: "50%",
//     position: "absolute",
//     textAlign: "center",
//     top: "50%",
//     transform: "translate(-50%, -50%)",
//   },
//   centerVertical: {
//     position: "absolute",
//     top: "50%",
//     transform: "translate(0, -50%)",
//   },
//   logo: {
//     background: "#ffffff",
//     borderRadius: "2px",
//     display: "inlineBlock",
//     maxWidth: "130px",
//     padding: "6px",
//   },
//   header: {
//     background: "#ffffff",
//     position: "fixed",
//     width: "100%",
//     zIndex: 4,
//   },
//   login: {
//     display: "inline-block",
//     float: "right",
//   },
//   mobileCollaborateBackground: {
//     backgroundImage:
//       'url("https://smorgborg.imgix.net/assets/peopleNetwork.png?fit=max&w=1200")',
//     backgroundSize: "cover",
//     bottom: 0,
//     height: "100%",
//     left: 0,
//     opacity: 0.1,
//     position: "absolute",
//     right: 0,
//     top: 0,
//     width: "100%",
//     zIndex: 1,
//   },
//   mobileCollaborateWrapper: {
//     height: "calc(100vh - 160px",
//     padding: "16px",
//     position: "relative",
//   },
//   mobileSection: {
//     minHeight: "60vh",
//     overflow: "hidden",
//     position: "relative",
//     width: "100%",
//   },
//   mobileTaskImageWrapper: {
//     height: "50vh",
//     marginLeft: "65%",
//     marginTop: "-20vh",
//     overflow: "hidden",
//     position: "absolute",
//   },
//   section: {
//     height: "calc(100vh - 128px)",
//     minHeight: "480px",
//     position: "relative",
//   },
//   title: {
//     color: CHARCOAL,
//     display: "inline-block",
//     float: "left",
//   },
// };

// export default function HomeCalender() {
//   return (
//     <div
//       style={{
//         ...styles.section,
//         height: "auto",
//         background: "#ffffff",
//         paddingLeft: "8%",
//         paddingRight: "8%",
//         display: "flex",
//         flexDirection: "flex-row",
//         alignItems: "center",
//       }}
//     >
//       <div className="flex flex-column" style={{ flexBasis: "50%" }}>
//         <div>
//           <img
//             alt="Building a Calendar"
//             src="https://smorgborg.imgix.net/assets/calendar.png?fit=max&w=1200"
//             style={{ width: "100%", padding: "10%" }}
//           />
//         </div>
//       </div>
//       <div
//         className="flex flex-column"
//         style={{ justifyContent: "flex-end", flexBasis: "50%" }}
//       >
//         <div>
//           <h1
//             style={{
//               fontWeight: "bolder",
//               fontSize: "18pt",
//               textAlign: "right",
//             }}
//           >
//             {"MAKING YOUR LIFE EASIER:"}
//           </h1>
//           <div style={{ textAlign: "right" }}>
//             <div style={{ clear: "both" }} />
//             <p style={{ fontSize: "18pt", float: "right" }}>
//               {"Multiple Administrators can be added to your Team"}
//               <br />
//               {"Automatic Email Collection Widget for Your Website"}
//               <br />
//               {"Consistent Design and Preview Options"}
//               <br />
//               {"Import and Export Features"}
//               <br />
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// import React from "react";

// const HomeCalender = () => {
//   const CHARCOAL = "#606060";
//   return (
//     <div className="section h-auto bg-white px-20 py-8 flex flex-row items-center">
//       <div className="flex flex-col flex-1">
//         <img
//           alt="Building a Calendar"
//           src="https://smorgborg.imgix.net/assets/calendar.png?fit=max&w=1200"
//           className="w-10/12 p-10"
//         />
//       </div>
//       <div className="flex flex-col justify-end flex-1">
//         <h1 className="font-bold text-2xl text-right mb-4">
//           {"MAKING YOUR LIFE EASIER:"}
//         </h1>
//         <div className="text-2xl leading-loose text-right">
//           <p>{"Multiple Administrators can be added to your Team"}</p>
//           <p>{"Automatic Email Collection Widget for Your Website"}</p>
//           <p>{"Consistent Design and Preview Options"}</p>
//           <p>{"Import and Export Features"}</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HomeCalender;

//mobile responsive

import React from "react";

const HomeCalender = () => {
  const CHARCOAL = "#606060";
  return (
    <div className="section h-auto bg-white px-6 md:px-20 py-8 flex flex-col md:flex-row items-center">
      <div className="flex flex-col md:flex-1 md:pr-8 py-8 pl-12">
        <img
          alt="Building a Calendar"
          src="https://smorgborg.imgix.net/assets/calendar.png?fit=max&w=1200"
          className="w-10/12"
        />
      </div>
      <div className="flex flex-col md:justify-end md:flex-1">
        <h1 className="font-bold text-2xl md:text-right mb-4">
          {"MAKING YOUR LIFE EASIER:"}
        </h1>
        <div className="text-2xl leading-loose md:text-right">
          <p>{"Multiple Administrators can be added to your Team"}</p>
          <p>{"Automatic Email Collection Widget for Your Website"}</p>
          <p>{"Consistent Design and Preview Options"}</p>
          <p>{"Import and Export Features"}</p>
        </div>
      </div>
    </div>
  );
};

export default HomeCalender;
