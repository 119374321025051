import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import DefaultLogo from "./email/DefaultLogo";

function LeftSidebar({ orgName, items, baseUrl }) {
  const [active, setActive] = useState("Dashboard");
  const path = useLocation().pathname;

  useEffect(() => {
    let str = path.split("/")[3];
    if (str && path.split("/")[1] !== "user") {
      str = str.charAt(0).toUpperCase() + str.slice(1);
      setActive(str);
    } else {
      if (path.split("/")[2] === "setting") {
        setActive("Setting");
      } else {
        setActive("Dashboard");
      }
    }
  }, [path, orgName]);

  return (
    <div className="h-full text-white" style={{ background: "#001529" }}>
      <div className="p-3">
        <DefaultLogo path={path} />
      </div>
      <div className="">
        {items?.map((list, i) => (
          <div key={i}>
            <Link to={`${baseUrl}${list?.url}`}>
              <div
                className={
                  active === list.name
                    ? "bg-blue-700 p-4 cursor-pointer text-white"
                    : "hover:bg-blue-400 p-4 cursor-pointer text-white"
                }
                onClick={() => setActive(list?.name)}
              >
                <p className="pl-12 text-sm"> {list?.name} </p>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default LeftSidebar;
