import React from 'react'
function BaseTabs({ active, handleTab, tabList }) {
    return (
        <>
            <div className='w-full flex justify-between gap-4 mb-4 border-b rounded-sm cursor-pointer'>
                {
                    tabList.map((tab, i) => (

                        <div className={active == tab ? 'active-tab w-full text-center pb-2' : 'w-full text-center pb-2 hover:text-blue-500'}
                            onClick={() => handleTab(tab)} key={i}>
                            {tab}
                        </div>
                    ))
                }

            </div>
        </>
    )
}

export default BaseTabs
