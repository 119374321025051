import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BaseTable from "../components/BaseTable";
import {
  getReqParamheader,
  deleteReqParamheader,
  postReqParamheader,
} from "../services/apiCall";
import { getItem, setItem } from "../utils/storage";
import { API_URL_CONSTANT } from "../constants/apiConstant";
import { ToastContainer, toast } from "react-toastify";
import PageSnipper from "../components/PageSnipper";
// import { DateTime } from "luxon";

function ArticleHome() {
  const [articleList, setArticleList] = useState([]);
  const [orgId, setOrg] = useState("");
  const [userId, setUser] = useState("");
  const [loading, setLoading] = useState(false);
  const path = useLocation().pathname;
  const navigate = useNavigate();

  // const formatDate = (dateTimeString) => {
  //   const dt = DateTime.fromISO(dateTimeString, { zone: "utc" });
  //   return dt.toFormat("dd/MM/yyyy");
  // };

  useEffect(() => {
    let userid = getItem("user_id");
    let orgid = path.split("/")[2];
    setItem("org_id", orgid);
    setOrg(orgid);
    setUser(userid);
  }, []);

  useEffect(() => {
    if (orgId) {
      getArticleData(orgId);
    }
  }, [orgId]);

  const handleDelete = (id) => {
    let a = window.confirm("Are you sure you want to delete this article?");
    if (a) {
      setLoading(true);
      let url = `${API_URL_CONSTANT.baseUrl}/delete_articles/${id}/${orgId}/${userId}`;
      deleteReqParamheader(url)
        .then((res) => {
          if (res?.status) {
            // console.log('delete article response', res?.data)
            getArticleData(orgId);
            setLoading(false);
            toast.success(res?.data?.message);
          } else {
            console.log("response err", res?.message);
          }
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
          toast.error(err?.response?.data?.message);
        });
    }
  };
  const handleRestore = (id) => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/restore_articles/${id}/${orgId}/${userId}`;
    postReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          // console.log('restore article response', res?.data)
          getArticleData(orgId);
          setLoading(false);
          toast.success(res?.data?.message);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };
  const handlePublice = (id) => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/publish_Article/${id}/${userId}`;
    postReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          // console.log('publice article response', res?.data)
          getArticleData(orgId);
          setLoading(false);
          toast.success(res?.data?.message);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const handleEdit = (id) => {
    navigate(`${path}/${id}`);
  };

  const handleView = (id) => {
    navigate(`/view/${id}`);
  };

  const getArticleData = (id) => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/All_article_details/${id}/${userId}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          // console.log("get article list response", res?.data);
          setArticleList(res?.data?.data);
          setLoading(false);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        setTimeout(() => {
          toast.error(err?.response?.data?.message);
        }, 1000);
      });
  };

  return (
    <>
      {" "}
      {loading ? (
        <PageSnipper loading={loading} />
      ) : (
        <div className="px-6">
          <div className="flex justify-between mb-3">
            <p className="text-2xl"> Articles ({articleList.length}) </p>
            <p className="text-small text-blue-500 mt-2 cursor-pointer mb-4">
              <Link to={`${path}/create`}>Create an Article</Link>
            </p>
          </div>

          {/* <p className="text-small text-blue-500 mt-2 cursor-pointer mb-4">
            <Link to={`${path}/create`}>Create an Article</Link>
          </p> */}
          {articleList?.length > 0 ? (
            <div>
              <BaseTable
                header={[
                  "Title",
                  "Document Status",
                  "Last Accessed",
                  "Actions",
                ]}
                data={articleList}
                remove={handleDelete}
                edit={handleEdit}
                restore={handleRestore}
                publice={handlePublice}
                view={handleView}
                type="article"

                // data={articleList.map((article) => ({
                //   ...article,
                //   published: formatDate(article.published),
                // }))}
                // remove={handleDelete}
                // edit={handleEdit}
                // restore={handleRestore}
                // publice={handlePublice}
                // view={handleView}
                // type="article"
              />
            </div>
          ) : (
            <div className="w-full text-center">No Article found</div>
          )}
          <ToastContainer />
        </div>
      )}
    </>
  );
}

export default ArticleHome;
