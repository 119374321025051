import React, { useState, useRef, useEffect } from 'react'

function DefaultArtical({ data, id, hs, hf, ts, tf, type, save, read, currentid }) {

    const HtmlToReactParser = require('html-to-react').Parser;
    const htmlToReactParser = new HtmlToReactParser();
    let desL = htmlToReactParser.parse(data?.Content?.slice(0, 300));
    let desM = htmlToReactParser.parse(data?.Content);
    let title = htmlToReactParser.parse(data?.Title);
    let ed = htmlToReactParser.parse(data?.Event_Description);
    let ed_excerpt = htmlToReactParser.parse(data?.Event_Description?.slice(0, 300));
    let url = `https://smorgborg.org/view/${id}`

    const containerRef = useRef(null);

    useEffect(() => {
        //set image params here
        if (containerRef.current) {
            let img = new Image();
            img.src = data?.Featured_Photo;
            img.onload = () => {
                let aratio = (img.height / img.width).toFixed(2);
                let w = img.width > 600 ? 600 : img.width;
                let h = img.width > 600 ? (600 * aratio) : img.height;
                save(currentid, w.toString(), h.toString());
            }
        }
    }, []);

    var newDateOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    }

    return (
        <>
            {
                type && type === 'event' ?
                <table border="0" cellSpacing="0" width="100%">
                <tbody>
                <tr>
                    <td width="600" align="center">
                    <div style={{ textAlign: 'start' }}>
                        <p style={{ fontSize: `${hs}`, fontFamily: `${hf}` }}><a target="_blank" href={data?.Event_Website}>{data?.Event_Name}</a></p>
                        {
                            data?.Featured_Photo &&
                            <div style={{ width: '100%', textAlign: '-webkit-center' }} >
                                <a target="_blank" href={data?.Event_Website}><img ref={containerRef} src={data?.Featured_Photo} style={{ maxHeight: "600px", maxWidth: "600px" }} alt="feature photo" /></a>
                            </div>

                        }
                        <p style={{ fontSize: `${ts}`, fontFamily: `${tf}` }}> {data?.Event_Start_Date.toLocaleString("en-US", newDateOptions)}</p>
                        {/* <p style={{ fontSize: `${ts}`, fontFamily: `${tf}` }}>End Event : {data?.Event_End_Time}</p> */}
                        
                        <p width="600" style={{fontSize: `${ts}`, fontFamily: `${tf}`, width: "600px", wordWrap: "break-word"}}>
                            {
                                read ?
                                    ed_excerpt
                                    :
                                    ed  
                            }
                            {
                                read ?
                                    <a href={data?.Event_Website} target='_blank' >...Read more</a>
                                    :
                                    null
                            }
                        </p>
                        {/* <a   target='_blank'>
                            <button style={{ padding: '8px 16px', background:'blue', fontSize: '16px', color: 'white', border: 'none', borderRadius: '2px' }}>
                                View Event
                            </button>
                        </a> */}

                    </div>
                    </td>
                    </tr>
                    </tbody>
                    </table>
                    :
                    <table border="0" cellSpacing="0" width="100%">
                    <tbody>
                    <tr>
                        <td width="600" align="center">
                    <div style={{ textAlign: 'start' }}>
                    <a target="_blank" href={url}><p style={{ fontSize: `${hs}`, fontFamily: `${hf}` }}>
                            {title}
                        </p></a>
                        {
                            data?.Featured_Photo && <div style={{ width: '100%', textAlign: '-webkit-center' }} >
                                <a target="_blank" href={url}><img ref={containerRef} src={data?.Featured_Photo} style={{ maxHeight: "600px", maxWidth: "600px" }} alt="feature photo" /></a>
                            </div>
                        }

                        <p style={{ fontSize: `${ts}`, fontFamily: `${tf}`, width: "600px" }}>
                            {
                                read ?
                                    desL
                                    :
                                    desM
                            }
                            {
                                read ?
                                    <a href={url} target='_blank' >...Read more</a>
                                    :
                                    null
                            }

                        </p>

                    </div>
                    </td>
                    </tr>
                    </tbody>
                    </table>
            }

        </>

    )
}

export default DefaultArtical
