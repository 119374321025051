import React, { useState, useEffect } from "react";
import { Button, Space, Spin } from "antd";
import { ToastContainer, toast } from "react-toastify";
import BaseTable from "../components/BaseTable";
import PageSnipper from "../components/PageSnipper";

import { getReqParamheader, deleteReqParamheader } from "../services/apiCall";
import { API_URL_CONSTANT } from "../constants/apiConstant";
import { useLocation, useNavigate } from "react-router-dom";
import { getItem, setItem } from "../utils/storage";

function EventsHome() {
  const minDays = 7;
  const maxDays = 28;
  const [eventList, setEventList] = useState([]);
  const [eventListId, setEventListId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [org, setOrg] = useState("");
  const [user, setUser] = useState("");
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const [currentDays, setCurrentDays] = useState(7);

  useEffect(() => {
    let userid = getItem("user_id");
    let orgid = path.split("/")[2];
    if (userid && orgid) {
      setItem("org_id", orgid);
      setOrg(orgid);
      setUser(userid);
    }
  }, []);

  useEffect(() => {
    if (user && org && currentDays < 29) {
      getEventData();
    }
  }, [user, org, currentDays]);

  const getEventData = () => {
    setLoading(true);

    let url =
      `${API_URL_CONSTANT.baseUrl}/events/${org}/${user}?eventDays=` +
      currentDays +
      `days`;

    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("get event list response", res?.data);
          setEventList(res?.data?.data);
          if (currentDays === 7) {
            setEventListId(res?.data?.All_eventdetails);
          } else {
            let currentEveListID = eventListId;
            if (currentEveListID) {
              const finalEventIdL = currentEveListID.concat(
                res?.data?.All_eventdetails
              );
              setEventListId(finalEventIdL);
            }
          }
          setLoading(false);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  };

  const handleDelete = (id, index) => {
    let a = window.confirm("Are you sure you want to delete this event?");
    if (a) {
      setLoading(true);
      let url = `${API_URL_CONSTANT.baseUrl}/delete_events/${id}/${eventListId[index]?._id}/${org}/${user}`;
      deleteReqParamheader(url)
        .then((res) => {
          if (res?.status) {
            console.log("delete event by id  response", res?.data);
            setLoading(false);
            getEventData();
            toast.success(res?.data?.message);
          } else {
            console.log("response err", res?.message);
          }
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
          toast.error(err?.response?.data?.message);
        });
    }
  };

  const handleView = (id) => {
    navigate(`/event/view/${id}`);
  };

  const handleCreate = () => {
    navigate(`${path}/create`);
  };

  const incrementDays = () => {
    if (currentDays < 28) {
      setCurrentDays(currentDays + 7);
    }
  };

  const decrementDays = () => {
    if (currentDays > 7) {
      setCurrentDays(currentDays - 7);
    }
  };

  return (
    <>
      {loading ? (
        <PageSnipper loading={loading} />
      ) : (
        <div className="px-6">
          <div className="flex justify-between mb-3">
            <p className="text-2xl"> Events ({eventList?.length}) </p>
            <p
              className="text-small text-blue-500 mt-2 cursor-pointer mb-4"
              onClick={() => handleCreate()}
            >
              Create an Event
            </p>
          </div>
          {eventList?.length > 0 ? (
            <div>
              <BaseTable
                header={[
                  "Name",
                  "Event Organizer",
                  "Start Event",
                  "End Event",
                  "Actions",
                ]}
                data={eventList}
                remove={handleDelete}
                view={handleView}
                type="event"
              />
            </div>
          ) : (
            <div className="w-full text-center">No Events found</div>
          )}
          <div className="flex justify-center">
            <Space>
              <Button
                onClick={decrementDays}
                disabled={currentDays === minDays}
              >
                Previous
              </Button>
              <Button
                onClick={incrementDays}
                disabled={currentDays === maxDays}
              >
                Next
              </Button>
            </Space>
          </div>
          <ToastContainer />
        </div>
      )}
    </>
  );
}

export default EventsHome;
